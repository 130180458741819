<template>
    <div>
        <template v-if="verified == 1">
            <v-tooltip right>
                <template v-slot:activator="{on}">
                    <v-icon class="mdi mdi-check-decagram-outline ml-2 green--text text--darken-3" v-on="on" large></v-icon>
                </template>
                <span>{{ $t('message.emailVerified') }}</span>
            </v-tooltip>
        </template>
        <template v-else>
            <v-btn :loading="verifying" @click="verifyFirebaseEmail()" class="ml-3">{{ $t('message.verifyEmail') }}</v-btn>
        </template>
    </div>
</template>

<script>
import {api} from "Api";
import {mapFields} from "vuex-map-fields";
import {mapFieldsPrefixed} from "Helpers/vuex-map-fields-prefixed";
export default {
    name: "VerifyEmailFirebase",
    props: ['emailId','verified'],
    data(){
        return {
            verifying: false
        }
    },
    computed: {
        statePrefix() {
            return 'update'
        },
        ...mapFieldsPrefixed('user',{
            User__fb_verified: 'User__fb_verified'
        },'statePrefix'),
    },
    methods: {
        async verifyFirebaseEmail() {
            if(await this.$root.$confirm(this.$t('message.firebase') + ': ' + this.emailId, this.$t('message.confirmations.continueEmailVerifyAction'), {color: 'orange'})){
                this.verifying = true;
                api
                    .put('/users/' + this.emailId + '/verify')
                    .then((response) => {
                        if(response.data.status == 'success') {
                            this.User__fb_verified = response.data.verified ? 1 : null;
                            this.$toast.success(this.$t('message.successes.emailVerified'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                        } else {
                            this.$toast.error(this.$t('messages.errors.emailNotVerified'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                        this.verifying = false;
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('messages.errors.emailNotVerified'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.verifying = false;
                    })
            }
        }
    }
}
</script>

<style scoped>

</style>